import Slider from './slider.js'
import Glide, {
  Anchors,
  Autoplay,
  Breakpoints,
  Controls,
  Images,
  Keyboard,
  Swipe,
} from '@glidejs/glide/dist/glide.modular.esm'

// Document ready
function ready(fn) {
  // see if DOM is already available
  if (
    document.readyState === 'complete' ||
    document.readyState === 'interactive'
  ) {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}

// Doc ready handler
ready(function () {
  const addOffClick = (e, cb) => {
    const offClick = (evt) => {
      if (e !== evt) {
        cb();
        document.removeEventListener('click', offClick);
      }
    };
    document.addEventListener('click', offClick);
  };

  const mql = window.matchMedia('(max-width: 576px)');
  try {
    mql.addEventListener('change', listenerCallback);
  } catch (e) {
    mql.addListener(listenerCallback);
  }

  // Screen size changes to desktop
  function listenerCallback(evt) {
    if (evt.matches) {
      // turn off search toggle
      document.querySelector('.header--top--search').classList.remove('active');
    }
  }

  // Scroll to id
  let smoothScroll = function (identifier) {
    let element = document.querySelector(identifier);
    if (element) {
      let topPos = element.offsetTop;
      window.scroll({
        top: topPos,
        behavior: 'smooth',
      });
    }
  };

  // Scroll to hash on load
  let hash = window.location.hash.substr(1);
  if (hash !== '') {
    smoothScroll('#' + hash);
  }

  // Search form
  let searchToggle = document.querySelector('.search-toggle');
  if (searchToggle) {
    searchToggle.addEventListener('click', function (e) {
      e.preventDefault();
      document.querySelector('.header--top--search').classList.toggle('active');
      document
        .querySelector('.header--top--search input[type="search"]')
        .focus();
    });
  }

  if (document.querySelector('.brands.slides')) {
    Slider.init('.brands.slides', {
      animationDuration: 1200,
      autoplay: 2500,
      perView: 4,
      gap: 0,
      breakpoints: {
        1200: {
          perView: 3,
        },
        920: {
          perView: 2,
        },
        600: {
          perView: 1,
        },
      },
    });
  }

  if (document.querySelector('.gallery.slides')) {
    Slider.init('.gallery.slides', {
      animationDuration: 1200,
      autoplay: 2500,
      perView: 3,
      gap: 0,
      breakpoints: {
        920: {
          perView: 2,
        },
        540: {
          perView: 1,
        },
      },
    });
  }

  if (document.querySelector('.main-banner.slides .main-banner--slides')) {
    // Initiate Glide
    let banner = new Glide('.main-banner.slides .main-banner--slides', {
      type: 'carousel',
      animationDuration: 800,
      autoplay: 5000,
      perView: 1,
      gap: 0,
    });

    // Pause banner animation if video is playing
    const videos = document.querySelectorAll('.main-banner.slides video');
    for (let i = 0; i < videos.length; i++) {
      videos[i].addEventListener('play', () => {
        banner.pause();
      });
      videos[i].addEventListener('ended', () => {
        banner.play();
      });
    }

    // Handle bullet click event
    let bullets = document.querySelectorAll('.main-banner .glide__bullet');
    for (let i = 0; i < bullets.length; i++) {
      bullets[i].addEventListener('click', function (e) {
        banner.go(this.dataset.glideDir);
      });
    }

    // Change the active bullet
    const activeBullet = 'glide__bullet--active';
    const changeActiveBullet = (newIndex, containerElement) => {
      const glideDir = containerElement.querySelector(
        `[data-glide-dir="=${newIndex}"]`
      );

      let activeEl = containerElement.querySelector(`.${activeBullet}`);
      if (activeEl) {
        activeEl.classList.remove(activeBullet);
      }

      if (glideDir) {
        glideDir.classList.add(activeBullet);
      }
    };

    // Update bullet and pause video on banner move
    const bulletsContainerElement = document.querySelector(
      '.main-banner .glide__bullets'
    );
    banner.on('run', () => {
      // reset videos
      for (let i = 0; i < videos.length; i++) {
        videos[i].pause();
        videos[i].currentTime = 0;
      }

      // Update bullet
      requestAnimationFrame(() => {
        changeActiveBullet(banner.index, bulletsContainerElement);
      });
    });

    banner.mount({ Controls, Autoplay, Keyboard, Swipe, Images, Anchors });
  }

  if (document.querySelector('.product-slider--content.slides')) {
    let productSliders = document.querySelectorAll(
      '.product-slider--content.slides'
    );
    for (let i = 0; i < productSliders.length; i++) {
      let products = new Glide(productSliders[i], {
        type: 'carousel',
        animationDuration: 1200,
        // autoplay: 5000,
        perView: 5,
        breakpoints: {
          1200: {
            perView: 4,
          },
          920: {
            perView: 3,
          },
          720: {
            perView: 2,
          },
          540: {
            perView: 1,
          },
        },
      });
      products.mount({
        Controls,
        Autoplay,
        Keyboard,
        Swipe,
        Breakpoints,
        Images,
        Anchors,
      });
    }
  }

  if (document.querySelector('a.share')) {
    let sharePopup = function (el, intWidth, intHeight, blnResize) {
      // Set values for window
      intWidth = intWidth || '500';
      intHeight = intHeight || '400';
      var strResize = blnResize ? 'yes' : 'no';

      // Set title and open popup with focus on it
      var strTitle =
          typeof el.getAttribute('title') !== 'undefined'
            ? el.getAttribute('title')
            : 'Social Share',
        strParam =
          'width=' +
          intWidth +
          ',height=' +
          intHeight +
          ',resizable=' +
          strResize;
      window.open(el.href, strTitle, strParam).focus();
    };

    let social = document.querySelectorAll('a.share');
    for (let i = 0; i < social.length; i++) {
      social[i].addEventListener('click', function (e) {
        e.preventDefault();
        sharePopup(this);
      });
    }
  }

  if (document.querySelector('.max-mega-menu .close')) {
    document
      .querySelector('.max-mega-menu .close')
      .addEventListener('click', function (e) {
        e.preventDefault();
        jQuery('.max-mega-menu').data('maxmegamenu').hideMobileMenu();
        return false;
      });
  }

  const ipGetLocale = async () => {
    const key = system.ipinfo_key;
    const ip = system.ip_address;

    if (!key || !ip) {
      return;
    }

    const response = await fetch(`https://ipinfo.io/${ip}?token=${key}`);
    if (!response.ok) {
      console.log('Unable to determine country code via IP address')
      return;
    }

    return await response.json();
  }

  const geoGetLocale = async (lat, lng) => {
    if (!lat || !lng) {
      return;
    }

    if (!system.geonames_username) {
      return;
    }

    const response = await fetch(`https://secure.geonames.org/countryCodeJSON?username=${system.geonames_username}&lat=${lat}&lng=${lng}`);
    if (!response.ok) {
      console.log('Unable to determine country code via Geolocation')
      return;
    }

    return await response.json();
  }

  const handleLocale = (country) => {
    if (!country) {
      return;
    }

    let disabled = true;
    let title, message;
    let links = '';
    let eu_countries = ['AT', 'BE', 'CZ', 'DE', 'DK', 'ES', 'FI', 'FR', 'IE', 'IT', 'LU', 'NL', 'PT', 'SE', 'SI', 'SK'];
    if ((system.blog_id == 1 || system.blog_id == 3)  && eu_countries.includes(country)) { // UK or US site, but EU IP address
      disabled = false;
      title = 'Are you located in Europe?';
      message = 'Would you like to switch to our European store?';
      links = `<a href="${system.blog_urls.eu}">Yes</a>`
    }
    else if (system.blog_id == 2 && !eu_countries.includes(country) && country != 'US') {  // EU site, but not an EU IP address
      disabled = false;
      title = 'Are you located outside Europe?';
      message = 'Would you like to switch to our Global store?';
      links = `<a href="${system.blog_urls.global}">Yes</a>`
    }
    else if (system.blog_id == 3 && !eu_countries.includes(country) && country != 'US') {  // US site, but not an EU IP address
      disabled = false;
      title = 'Are you located outside the USA?';
      message = 'Would you like to switch to our Global store?';
      links = `<a href="${system.blog_urls.global}">Yes</a>`
    }
    else if ((system.blog_id == 1 || system.blog_id == 2) && country == 'US') {  // UK or EU site, but not a US location
      disabled = false;
      title = 'Are you located in the USA?';
      message = 'Would you like to switch to our US store?';
      links = `<a href="${system.blog_urls.us}">Yes</a>`
    }

    let deactivate = Cookies.get('country-switcher-disable');
    if (deactivate && deactivate == 1) {
      disabled = true;
    }

    if (!disabled) {
      const switcher = document.querySelector('.country-switcher');

      switcher.querySelector('h3 span').textContent += title;
      switcher.querySelector('p').textContent += message;
      switcher.querySelector('.buttons div').insertAdjacentHTML('afterbegin', links);

      switcher.classList.add('show');
    }
  }

  const geoLocate = async () => {
    return new Promise((resolve, reject) =>
      navigator.permissions ?

        // Permission API is implemented
        navigator.permissions.query({
          name: 'geolocation'
        }).then(permission =>
          // is geolocation granted?
          permission.state === "granted"
            ? navigator.geolocation.getCurrentPosition(pos => resolve(pos.coords))
            : resolve(null)
        ) :

        // Permission API was not implemented
        reject(new Error("Permission API is not supported"))
    )
  }

  geoLocate()
    .then(async (coords) => {
      if (coords) {
        const response = await geoGetLocale(coords.latitude, coords.longitude);
        if (response?.countryCode) {
          handleLocale(response?.countryCode);
        }
      } else {
        const response = await ipGetLocale();
        if (response?.country) {
          handleLocale(response?.country);
        }
      }
    });


  let closeBtns = document.querySelectorAll('.country-switcher .close');
  for (let i = 0; i < closeBtns.length; i++) {
    closeBtns[i].addEventListener('click', function (e) {
      e.preventDefault();
      Cookies.set('country-switcher-disable', 1, {
        expires: 7,
        secure: true,
        sameSite: 'strict',
      });
      document.querySelector('.country-switcher').classList.remove('show');
    });
  }
});
