import Glide, { Controls, Breakpoints, Anchors, Autoplay, Images, Keyboard, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

let Slider = {
    settings: {
        type: 'carousel',
        autoHeight: false
    },

    setOptions: function (options) {
        if (typeof options === 'object') {
            for (let key in options) {
                if (options.hasOwnProperty(key)) {
                    this.settings[key] = options[key];
                }
            }
        }
    },

    init: function (selector, options) {
        this.setOptions(options);

        let glides = [];
        const sliders = document.querySelectorAll(selector);
        if (sliders) {
            for (let i = 0; i < sliders.length; i++) {
                let glide = new Glide(sliders[i], this.settings);

                if (this.settings.autoHeight) {
                    let _this = this;
                    glide.on('build.after', function () {
                        _this.updateHeight(sliders[i]);
                    });
                    glide.on('run.after', function () {
                        _this.updateHeight(sliders[i]);
                    });
                }

                glide.mount({Controls, Autoplay, Keyboard, Swipe, Breakpoints, Images, Anchors});
                glides.push(glide);
            }
        }
        return glides;
    },

    updateHeight: function (selector) {
        const activeSlide = selector.querySelector('.glide__slide--active');
        const activeSlideHeight = activeSlide ? activeSlide.offsetHeight : 0;

        const glideTrack = selector.querySelector('.glide__track');
        const glideTrackHeight = glideTrack ? glideTrack.offsetHeight : 0;

        if (activeSlideHeight !== glideTrackHeight) {
            glideTrack.style.height = `${activeSlideHeight}px`;
        }
    }
}
export default Slider;